<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6">
          <div class="title">{{ title }}</div>
        </div>
        <div class="col-lg-6 text-right">
          <ButtonCsvExport
            v-if="data.length > 0"
            :data="data"
            :filename="title"
            class="csv-export-button"
          />
        </div>
      </div>
      <p v-if="!loadAllowed || (dataLoading && data.length === 0)" class="info-box-load-data text-center">
        <button
          :class="{ 'table-button': true, 'table-button--disabled': dataLoading }"
          :disabled="dataLoading"
          @click="toggleLoad"
        >
          {{ $t('videoStats.load_data') }}
        </button>
      </p>
      <p
        v-else-if="data.length === 0"
        class="alert alert-info"
      >
        {{ $t('videoStats.no_data_found') }}
      </p>
      <Preloader v-if="dataLoading" />
      <table
        v-if="data.length > 0"
        class="table table-hover performance-statistics-table"
      >
        <thead>
          <tr>
            <th :style="`width: ${firstRowWidth}px;`">{{ $t('videoStats.title') }}</th>
            <slot name="afterFirstTh" />
            <th>{{ $t('videoStats.inViewDisplays') }}</th>
            <th>{{ $t('videoStats.videoPlays') }}</th>
            <th>{{ $t('videoStats.adStarts') }}</th>
            <th>{{ $t('videoStats.videoStarts') }}</th>
            <th :title="$t('videoStats.conversion_rate_info')">{{ $t('videoStats.play_conv_rate') }}</th>
            <th>{{ $t('videoStats.replays') }}</th>
            <slot name="afterLastTh" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(record, index) in dataView" :key="`${record.title}-${index}`">
            <slot name="firstTd" :record="record">
              <td>
                {{ record.title }}
              </td>
            </slot>
            <slot name="afterFirstTd" :data="data" :record="record" />
            <td>{{ record.inViewDisplays | formatNumber }}</td>
            <td>{{ record.videoPlays | formatNumber }}</td>
            <td>{{ record.adStarts | formatNumber }}</td>
            <td>{{ record.videoStarts | formatNumber }}</td>
            <td>{{ record.conversionRate | formatPercentage }}</td>
            <td>{{ record.replays | formatNumber }}</td>
            <slot name="afterLastTd" :record="record" />
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-lg-12 text-center table-footer">
          <button
            v-if="dataView.length < data.length"
            class="table-button table-button--load-more"
            @click="loadMore"
          >
            <PlusIcon class="table-button--load-more__icon" />
            <span class="table-button--load-more__label">
              {{ $t('buttons.load_more_items') }}
            </span>
          </button>
          <div v-if="data.length > 0" class="count-label">
            {{ $t('videoStats.displayed') }}
            <span class="count-label__numbers">
              {{ `${dataView.length}/${data.length}` }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonCsvExport from '@/components/shared/ButtonCsvExport'
import Preloader from '@/components/preloader/Preloader'
import { dataHubVideoApi } from '@/api/datahub'
import PlusIcon from '@/assets/img/svg/plus.svg?inline'

export default {
  name: 'PerformanceStatisticsTable',
  props: {
    apiUrl: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    firstRowWidth: {
      type: Number,
      required: true
    }
  },
  components: {
    PlusIcon,
    ButtonCsvExport,
    Preloader
  },
  data () {
    return {
      data: [],
      limit: 10,
      dataLoading: false,
      loadAllowed: false
    }
  },
  computed: {
    dataView () {
      return this.data.slice(0, this.limit)
    }
  },
  methods: {
    loadMore () {
      this.limit += 10
    },
    toggleLoad () {
      this.loadAllowed = true
      this.reloadStats()
    },
    reloadStats () {
      if (this.loadAllowed) {
        this.dataLoading = true
        dataHubVideoApi().get(this.apiUrl)
          .then(response => {
            this.data = response.data
            this.limit = 10
          })
          .catch(error => {
            this.data = []
            console.error(error)
          })
          .finally(() => {
            this.dataLoading = false
          })
      }
    }
  }
}
</script>
<style scoped lang="scss">
.csv-export-button {
  margin-bottom: 0.5rem;
}
.title {
  @include font(700 16px "Roboto");
  margin-bottom: 1rem;
}
.info-box-load-data {
  background-color: #ECF2FF;
  padding: rem(16px) rem(0px);
  border-radius: rem(6px);
}
.table-button {
  @include font(500 14px "Roboto");
  border-radius: rem(6px);
  border: 1px solid #D1DBE4;
  cursor: pointer;
  background-color: #6599FE;
  color: #FFFFFF;
  padding: rem(10px) rem(16px);
  &--load-more {
    border: none;
    background-color: #ECF2FF;
    &__icon {
      @include size(rem(14px));
      fill: #7390C8;
      float: left;
      margin-right: rem(8px);
    }
    &__label {
      @include font(700 14px "Roboto");
      color: #7390C8;
    }
  }
  &--disabled {
    background-color: #D1DBE4;
    cursor: default;
    &:hover {
      background-color: #D1DBE4;
    }
  }
}
.performance-statistics-table {
  thead {
    background: #F5F5F9;
    border-radius: rem(6px);
    padding: rem(16px) 0 rem(16px) rem(16px);
    th {
      @include font(700 12px "Roboto");
      color: #8490A7;
    }
  }
  td {
    @include font(400 12px "Roboto");
    vertical-align: middle;
    height: rem(65px);
  }
}
.table-footer {
  position: relative;
  padding-bottom: rem(17px);
  .count-label {
    position: absolute;
    top: rem(5px);
    right: rem(10px);
    &__numbers {
      @include font(700 12px "Roboto");
    }
  }
}
</style>
