<template>
  <div v-if="videoStatsData">
    <div class="tab">
      <VideoStatsInfoValue
        v-for="(statsValue, index) in tabs[activeTab]"
        :key="`stats-value-${statsValue.label}-${index}`"
        :video-stats-data="videoStatsData"
        :chart-colors="chartColors"
        :stats-value="statsValue"
      />
    </div>
  </div>
</template>

<script>
import VideoStatsInfoValue from '@/components/video/videoStats/VideoStatsInfoValue'
export default {
  name: 'VideoStatsInfo',
  components: { VideoStatsInfoValue },
  props: {
    activeTab: {
      type: Number
    },
    videoStatsData: {
      type: Object
    },
    chartColors: {
      type: Object
    }
  },
  computed: {
    tabs () {
      return [
        [
          { label: 'inViewDisplays', propName: 'inViewDisplays' },
          { label: 'videoPlays', propName: 'videoPlays' },
          { label: 'adStarts', propName: 'adStarts' },
          { label: 'videoStarts', propName: 'videoStarts' },
          { label: 'playConversionRate', propName: 'playConversionRate', format: 'percentage', hideAverage: true },
          { label: 'replays', propName: 'replays' },
          {
            label: 'totalVideoViews',
            colorPropName: 'totalVideoViews',
            totalValue: this.totalVideoViews,
            hideAverage: true
          }
        ],
        [
          { label: 'concurrentUsers', propName: 'concurrentUsers', colorPropName: 'uniqueUsers' },
          { label: 'avgVideosPerUser', propName: 'viewsPerUser', colorPropName: 'uniqueUsers', hideAverage: true }
        ],
        [
          { label: 'pass25Plays', propName: 'pass25Plays' },
          { label: 'pass50Plays', propName: 'pass50Plays' },
          { label: 'pass75Plays', propName: 'pass75Plays' },
          { label: 'pass100Plays', propName: 'pass100Plays' },
          {
            label: 'avgPassPerc',
            colorPropName: 'avgPassPerc',
            totalValue: this.totalAveragePlay,
            hideAverage: true
          }
        ],
        [
          { label: 'inViewDisplays', propName: 'inViewDisplays' },
          { label: 'adStarts', propName: 'adStarts' },
          { label: 'adSkipRate', propName: 'adSkipRate', hideAverage: true, format: 'percentage' },
          { label: 'adFinishRate', propName: 'adFinishRate', hideAverage: true, format: 'percentage' },
          { label: 'adConversionRate', propName: 'adConversionRate', hideAverage: true, format: 'percentage' }
        ]
      ]
    },
    totalVideoViews () {
      const result = this.videoStatsData.videoPlays.total + this.videoStatsData.replays.total
      return this.$options.filters.formatNumber(result)
    },
    totalAveragePlay () {
      const { total } = this.videoStatsData.avgPassPerc
      if (!total && total !== 0) {
        return 'N/A'
      }

      return `${this.$options.filters.formatNumber(total)} %`
    }
  }
}
</script>

<style lang="scss">
.tab {
  display: flex;
  gap: 1.3rem;
}
</style>
