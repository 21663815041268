<template>
  <div>
    <div class="title">{{ $t('videoStats.videoplays_portfolio', { company }) }}</div>
    <app-preloader v-if="loading"></app-preloader>
    <div style="height: 300px;">
      <app-line-chart
        :chart-data="videoViewsChartData"
        :height="300"
        :max-labels="24"
      >
      </app-line-chart>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import ChartMixin from '@/components/mixins/ChartMixin'
import Preloader from '@/components/preloader/Preloader'
import LineChart from '@/components/chart/LineChart'
import { dataHubVideoApi } from '@/api/datahub'

export default {
  name: 'VideoViewsChart',
  mixins: [ChartMixin],
  data () {
    return {
      loading: false,
      videoViewsChartData: {
        labels: [],
        datasets: []
      }
    }
  },
  components: {
    appLineChart: LineChart,
    appPreloader: Preloader
  },
  computed: {
    company () {
      return this.$store.getters.company.toUpperCase()
    }
  },
  methods: {
    formatTimeGroupDates (timeGroups, format = 'DD.MM HH:mm', addDay = 0) {
      return timeGroups.map(timeGroup => {
        if (addDay > 0) {
          return moment(timeGroup).add(addDay, 'days').format(format)
        }
        return moment(timeGroup).format(format)
      })
    },
    getVideoViews () {
      this.loading = true
      dataHubVideoApi().get('/videoViews')
        .then(response => {
          const { currentWeek, lastWeek } = response.data
          this.videoViewsChartData = {
            labels: this.formatTimeGroupDates(response.data.lastWeek.dimensions, 'DD.MM H:mm', 7),
            datasets: [
              {
                label: 'Current week',
                borderColor: '#1693c1',
                backgroundColor: 'rgba(22, 147, 193, 0.4)',
                data: this.trimZeroMetrics(currentWeek.metrics)
              },
              {
                label: 'Last week',
                backgroundColor: 'rgba(204, 204, 204, 0.5)',
                data: lastWeek.metrics
              }
            ]
          }
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.getVideoViews()
  }
}
</script>

<style lang="scss" scoped>
.title {
  @include font(700 16px "Roboto");
  color: #465674;
  margin-bottom: 1rem;
}
</style>
