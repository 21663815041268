<template>
  <button
    class="confirm-selection-button"
    title="Confirm Selection"
    type="button"
    @click="confirmSelection"
  >
    {{ $t('buttonComponent.confirm_selection') }}
  </button>
</template>

<script>
export default {
  name: 'ButtonConfirmSelection',
  methods: {
    confirmSelection () {
      this.$emit('confirm-selection')
    }
  }
}
</script>

<style scoped lang="scss">
.confirm-selection-button {
  @include font(500 14px "Roboto");
  border-radius: rem(6px);
  border: 1px solid #D1DBE4;
  cursor: pointer;
  background-color: #6599FE;
  color: #FFFFFF;
  padding: rem(9px) rem(16px);
  &--disabled {
    background-color: #D1DBE4;
    cursor: default;
    &:hover {
      background-color: #D1DBE4;
    }
  }
}
</style>
