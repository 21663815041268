export default {
  methods: {
    trimZeroMetrics (metrics) {
      let index = metrics.length - 1
      while (metrics[index] === 0) {
        index--
      }
      return metrics.slice(0, index + 1)
    }
  }
}
