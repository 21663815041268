<template>
  <div>
    <div class="title">{{ $t('videoStats.concurrent_users', { company }) }}</div>
    <app-preloader v-if="loading"></app-preloader>
    <div style="height: 300px;">
      <app-line-chart
        :chart-data="concurrentUsersChartData"
        :height="300"
        :max-labels="24"
      >
      </app-line-chart>
    </div>
  </div>
</template>

<script>
import ChartMixin from '@/components/mixins/ChartMixin'
import Preloader from '../../preloader/Preloader'
import LineChart from '../../chart/LineChart'
import { dataHubVideoApi } from '@/api/datahub'

export default {
  name: 'ConcurrentUsersChart',
  mixins: [ChartMixin],
  data () {
    return {
      loading: false,
      concurrentUsersChartData: {
        labels: [],
        datasets: []
      }
    }
  },
  components: {
    appLineChart: LineChart,
    appPreloader: Preloader
  },
  computed: {
    company () {
      return this.$store.getters.company.toUpperCase()
    }
  },
  methods: {
    getVideoConcurrentUsers () {
      this.loading = true
      dataHubVideoApi().get('/concurrentUsers')
        .then(response => {
          const { today, lastWeek } = response.data
          this.concurrentUsersChartData = {
            labels: Array.from(Array(25), (item, idx) => ('0' + idx).slice(-2) + ':00'),
            datasets: [
              {
                label: 'Today',
                borderColor: '#ffb22b',
                backgroundColor: 'rgba(255, 178, 43, 0.5)',
                data: this.trimZeroMetrics(today.metrics)
              },
              {
                label: 'Last week',
                backgroundColor: 'rgba(204, 204, 204, 0.5)',
                data: lastWeek.metrics
              }
            ]
          }
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.getVideoConcurrentUsers()
  }
}
</script>

<style lang="scss" scoped>
.title {
  @include font(700 16px "Roboto");
  color: #465674;
  margin-bottom: 1rem;
}
</style>
