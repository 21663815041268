<template>
  <div>
    <div class="row">
      <!-- Concurrent users chart -->
      <div class="col-lg-5">
        <div class="card">
          <div class="card-body">
            <ConcurrentUsersChart />
          </div>
        </div>
      </div>
      <!-- Video plays chart -->
      <div class="col-lg-7">
        <div class="card">
          <div class="card-body">
            <VideoViewsChart />
          </div>
        </div>
      </div>
    </div>

    <!-- Chart with Filter and leftSideInfo -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row m-b-10">
              <div class="col-lg-12">
                <Tabs
                  :tab-names="['video_performance', 'reach', 'playability', 'ad_performance']"
                  @tab-changed="tabChanged"
                >
                  <template #default="{ tabName }">
                    {{ $t(`videoStats.${tabName}`) }}
                  </template>
                </Tabs>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <VideoStatsChart
                  :loading="loading"
                  :active-tab="activeTab"
                  :video-stats-data="videoStatsData"
                >
                  <template #above-chart>
                    <!-- Filter -->
                    <div class="filter-row">
                      <Datepicker
                        class="filter-col"
                        type="date"
                        v-model="filter.startDate"
                        id="filter_startDate"
                        :label="$t('videoStats.filter.date_from')"
                        disable-form-group
                      />
                      <Datepicker
                        class="filter-col"
                        type="date"
                        v-model="filter.endDate"
                        id="filter_endDate"
                        :label="$t('videoStats.filter.date_to')"
                        disable-form-group
                      />
                      <Select
                        class="filter-col filter-site"
                        v-model="filter.site"
                        :options="sites"
                        no-empty-value
                        option-value="name"
                        :label="$t('videoStats.filter.site')"
                        id="filter_defaultSite"
                        disable-form-group
                      />
                      <div class="filter-col">
                        <ButtonConfirmSelection @confirm-selection="refreshAllStats"/>
                        <ButtonCsvExport
                          v-if="isExportButtonVisible"
                          :data="getStatsExportData()"
                          filename="video-stats"
                        />
                      </div>

                    </div>
                  </template>
                </VideoStatsChart>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <VideoStatsInfo
                  :active-tab="activeTab"
                  :video-stats-data="videoStatsData"
                  :chart-colors="chartColors"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Tables -->
    <div class="row">
      <div class="col-lg-12">
        <PerformanceStatisticsTable
          ref="tablePlayers"
          :api-url="`/player${filterQuery}`"
          :title="$t('videoStats.players_performance')"
          :first-row-width="150"
        >
          <template #afterFirstTh>
            <th style="width: 200px;" />
          </template>
          <template #afterFirstTd="{ record, data }">
            <td title="Videoplays">
              <ProgressBar
                :value="record.videoPlays"
                :max="data.reduce((sum, item) => sum + item.videoPlays, 0)"
                show-percentage
              />
            </td>
          </template>
        </PerformanceStatisticsTable>

        <PerformanceStatisticsTable
          ref="tableDomain"
          :api-url="`/domain${filterQuery}`"
          :title="$t('videoStats.sources_domain')"
          :first-row-width="300"
        />

        <PerformanceStatisticsTable
          ref="tableTopAsset"
          :api-url="`/topAsset${filterQuery}`"
          :title="$t('videoStats.top_assets')"
          :first-row-width="700"
        >
          <template #firstTd="{ record }">
            <td>
              {{ record.title }} <br> <strong>Video Id:</strong> {{ record.videoId }}
            </td>
          </template>
          <template #afterFirstTh>
            <th>{{ $t('videoStats.characterType.title') }}</th>
          </template>
          <template #afterFirstTd="{ record }">
            <td>{{ getCharacterType(record) }}</td>
          </template>
          <template #afterLastTh>
            <th></th>
          </template>
          <template #afterLastTd="{ record }">
            <td>
              <router-link
                v-if="record.videoId && record.videoId.length <= 10"
                tag="a"
                :to="{name: 'video_detail', params: {id: record.videoId}}"
                target="_blank"
                class="btn btn-default btn-sm"
                :title="$t('buttons.info')"
              >
                <i class="fas fa-info-circle"></i>
              </router-link>
            </td>
          </template>
        </PerformanceStatisticsTable>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Select from '@/components/form/select/Select'
import Datepicker from '@/components/form/Datepicker'
import ConcurrentUsersChart from '@/components/video/videoStats/ConcurrentUsersChart'
import VideoViewsChart from '@/components/video/videoStats/VideoViewsChart'
import VideoStatsInfo from '@/components/video/videoStats/VideoStatsInfo'
import VideoStatsChart from '@/components/video/videoStats/VideoStatsChart'
import { chartColors } from '@/services/videoStats/VideoChartColors'
import VideoStatsFilter from '@/model/VideoStatsFilter'
import ButtonCsvExport from '@/components/shared/ButtonCsvExport'
import ButtonConfirmSelection from '@/components/buttons/ButtonConfirmSelection'
import { dataHubVideoApi } from '@/api/datahub'
import PermissionService from '@/services/PermissionService'
import PerformanceStatisticsTable from '@/components/video/videoStats/PerformanceStatisticsTable'
import ProgressBar from '@/components/video/videoStats/ProgressBar'
import { mapGetters } from 'vuex'
import Tabs from '@/components/shared/Tabs'

export default {
  name: 'VideoDashboard',
  data () {
    return {
      loading: false,
      chartColors: chartColors,
      videoStatsData: null,
      activeTab: 0,
      filter: this._.cloneDeep(VideoStatsFilter)
    }
  },
  components: {
    Tabs,
    ProgressBar,
    PerformanceStatisticsTable,
    Select,
    Datepicker,
    ConcurrentUsersChart,
    VideoViewsChart,
    VideoStatsInfo,
    VideoStatsChart,
    ButtonCsvExport,
    ButtonConfirmSelection
  },
  methods: {
    tabChanged ({ index }) {
      this.activeTab = index
    },
    getCharacterType (record) {
      if (!record.characterType) {
        return ''
      }
      return this.$t(`videoStats.characterType.${record.characterType}`)
    },
    refreshAllStats () {
      this.$nextTick(() => {
        this.getVideoStats()
        this.$refs.tablePlayers.reloadStats()
        this.$refs.tableDomain.reloadStats()
        this.$refs.tableTopAsset.reloadStats()
      })
    },
    async callVideoApi (url) {
      try {
        return await dataHubVideoApi().get(`${url}${this.filterQuery}`)
      } catch (error) {
        console.error(error)
        return null
      }
    },
    async getVideoStats () {
      this.loading = true
      Promise.all([this.callVideoApi('/perf'), this.callVideoApi('/brandDetails')])
        .then(values => {
          const result = (values[0])?.data
          const chartData = (values[1])?.data
          if (result && chartData) {
            for (const key of Object.keys(result)) {
              if (chartData[key]) {
                result[key] = {
                  ...result[key],
                  ...chartData[key]
                }
              }
            }
            result.dimensions = chartData.dimensions
            this.videoStatsData = result
          }
          this.loading = false
        })
    },
    getStatsExportData () {
      if (this.videoStatsData === null) return []
      const exportData = []
      const keys = this.videoStatsData.dimensions
      const valuesToIgnore = ['dimensions', 'adSkipped', 'adCompleted', 'viewsPerUser']
      for (const statsKey in this.videoStatsData) {
        if (valuesToIgnore.includes(statsKey)) {
          continue
        }
        const statsItem = this.videoStatsData[statsKey]
        const exportItem = {
          title: this.$t(`videoStats.${statsKey}`)
        }
        if (statsItem.metrics) {
          statsItem.metrics.forEach((metric, metricIdx) => {
            exportItem[moment(keys[metricIdx]).format('DD.MM.YYYY HH:mm')] = metric
          })
        }
        exportData.push(exportItem)
      }
      return exportData
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    company () {
      return this.$store.getters.company.toUpperCase()
    },
    isExportButtonVisible () {
      const permission =
        PermissionService.REQUIRED_PERMISSIONS.VIDEO_STATISTICS_PERMISSIONS.exportButton
      return this.$store.getters['user/hasPermission'](permission)
    },
    sites () {
      const sites = [
        {
          id: 0,
          title: this.$t('videoStats.all_sites', { company: this.company })
        }
      ]
      const hiddenSites = this.vlm ? [] : [
        'automix', 'peknebyvanie', 'mollietvori', 'mamina', 'zazracnysvet', 'adam',
        'enjoy', 'reality', 'medialne', 'video', 'youtube', 'plustv'
      ]
      return sites.concat(this.$store.getters['site/allEnabledSorted']().filter((site) => hiddenSites.indexOf(site.name) < 0))
    },
    startDate () {
      return moment(this.filter.startDate).format('YYYY-MM-DD')
    },
    endDate () {
      return moment(this.filter.endDate).format('YYYY-MM-DD')
    },
    selectedSite () {
      return this.filter.site
    },
    filterQuery () {
      const dates = `fromDate=${this.startDate}&toDate=${this.endDate}`
      if (this.selectedSite === 0) {
        return `?${dates}`
      }
      return `?brand=${this.selectedSite}&${dates}`
    }
  },
  created () {
    this.getVideoStats()
  }
}
</script>

<style scoped lang="scss">
.filter-row {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  flex-wrap: wrap;
  margin-top: -1rem;
  margin-bottom: rem(20px);
  .filter-col {
    flex-grow: 1;
    min-width: rem(150px);
    margin: 1rem 1rem 0 0;
  }
  .filter-last-col {
    &__export-button {
      height: rem(38px);
      margin-top: rem(21px);
    }
  }
  .filter-site {
    margin-top: rem(-3px);
  }
}

</style>
