<template>
  <div>
    <slot name="above-chart" />
    <app-preloader v-if="loading"></app-preloader>
    <!-- display-legend is set to "false" as the legend is in '@/components/video/videoStats/VideoStatsInfo' -->
    <app-line-chart
      :chart-data="videoStatsChartData"
      :display-legend="false"
      :height="330"
      :max-labels="24"
    >
    </app-line-chart>
  </div>
</template>

<script>
import moment from 'moment'
import Preloader from '@/components/preloader/Preloader'
import LineChart from '@/components/chart/LineChart'
import { chartColors } from '@/services/videoStats/VideoChartColors'

export default {
  name: 'VideoStatsChart',
  props: {
    loading: {
      type: Boolean
    },
    activeTab: {
      type: Number
    },
    videoStatsData: {
      type: Object
    }
  },
  components: {
    appLineChart: LineChart,
    appPreloader: Preloader
  },
  computed: {
    videoStatsChartData () {
      if (this.videoStatsData === null) {
        return {
          labels: [],
          datasets: []
        }
      }

      let datasets = []
      if (this.activeTab === 0) {
        datasets = [
          { label: 'inViewDisplays', propName: 'inViewDisplays' },
          { label: 'videoPlays', propName: 'videoPlays' },
          { label: 'adStarts', propName: 'adStarts' },
          { label: 'videoStarts', propName: 'videoStarts' },
          { label: 'replays', propName: 'replays' }
        ].map(this.mapChartDataSet)
      }

      if (this.activeTab === 1) {
        datasets = [
          {
            label: this.$t('videoStats.concurrentUsers'),
            borderColor: chartColors.uniqueUsers,
            backgroundColor: chartColors.uniqueUsers,
            fill: false,
            data: this.videoStatsData.concurrentUsers.metrics
          }
        ]
      }

      if (this.activeTab === 2) {
        datasets = [
          { label: 'pass25Plays', propName: 'pass25Plays' },
          { label: 'pass50Plays', propName: 'pass50Plays' },
          { label: 'pass75Plays', propName: 'pass75Plays' },
          { label: 'pass100Plays', propName: 'pass100Plays' }
        ].map(this.mapChartDataSet)
      }

      if (this.activeTab === 3) {
        datasets = [
          { label: 'inViewDisplays', propName: 'inViewDisplays' },
          { label: 'adStarts', propName: 'adStarts' }
        ].map(this.mapChartDataSet)
      }

      return {
        labels: this.formatTimeGroupDates(this.videoStatsData.dimensions),
        datasets: datasets
      }
    }
  },
  methods: {
    mapChartDataSet ({ label, propName, colorPropName }) {
      return {
        label: this.$t(`videoStats.${label}`),
        borderColor: chartColors[colorPropName ?? propName],
        backgroundColor: chartColors[colorPropName ?? propName],
        fill: false,
        data: this.videoStatsData[propName]?.metrics ?? []
      }
    },
    formatTimeGroupDates (timeGroups, format = 'DD.MM HH:mm', addDay = 0) {
      if (!timeGroups) {
        return []
      }
      return timeGroups.map(timeGroup => {
        if (addDay > 0) {
          return moment(timeGroup).add(addDay, 'days').format(format)
        }
        return moment(timeGroup).format(format)
      })
    }
  }
}
</script>
